<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                    <b-col lg="11" offset-lg="1" sm="12">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                          <ValidationProvider name="Role Name (En)" vid="role_name" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="role_name"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                  {{ $t('research_manage.role_name_enn') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="role_name"
                                v-model="role.role_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                              </b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Role Name (Bn)" vid="role_name_bn" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="role_name_bn"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                  {{ $t('research_manage.role_name_bnn') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="role_name_bn"
                                v-model="role.role_name_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                              </b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                          </div>
                        </b-form>
                        </ValidationObserver>
                    </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { roleInformationStore, roleInformationUpdate } from '../../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getEditingData()
      this.role = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      loading: false,
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      role: {
        role_name: '',
        role_name_bn: ''
      }
    }
  },
  computed: {
  },
  methods: {
    getEditingData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.role.id) {
        result = await RestApi.putData(agriResearchServiceBaseUrl, `${roleInformationUpdate}/${this.id}`, this.role)
      } else {
        result = await RestApi.postData(agriResearchServiceBaseUrl, roleInformationStore, this.role)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('AgriResearch/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    }
  }
}
</script>
